<template>
  <div class="relative flex flex-col gap-5 overflow-hidden">
    <h2 class="text-tekstovyy text-6 lg:text-8.5 font-700">{{ title }}</h2>
    <slot :classes="['text-#455862 text-3.75 inside-class lg:text-4.5 leading-1.45 [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-2 [&>ul]:mt-2 [&>ul]:mb-2 [&>ul]:pt-0 [&>ul]:pb-0 [&>ul>li]:text-3.75 [&>ul>li]:lg:text-4.5 [&>ul>li]:leading-1.45 [&>ul>li]:list-disc! [&>ul>li>a]:text-firmennyy [&>ul>li>a]:underline [&>p>a]:text-firmennyy [&>p>a]:underline  [&>h2]:font-700 [&>h2]:text-textovyy [&>span]:font-500 [&>span]:text-firmennyy [&>a]:text-firmennyy [&>a]:underline ', { 'max-h-90 overflow-hidden': isClamp }]">
      <p
        ref="contentRef"
        class="text-#455862 text-3.75 lg:text-4.5 leading-1.45 inside-class [&>span]:font-500 [&>span]:text-firmennyy [&>h2]:font-700 [&>h2]:text-textovyy [&>a]:text-firmennyy [&>a]:underline [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-2 [&>ul]:mt-2 [&>ul]:mb-2 [&>ul]:pt-0 [&>ul]:pb-0 [&>ul>li]:text-3.75 [&>ul>li]:lg:text-4.5 [&>ul>li]:leading-1.45 [&>ul>li]:list-disc! [&>ul>li>a]:text-firmennyy [&>ul>li>a]:underline "
        :class="{ 'max-h-90 overflow-hidden': isClamp }"
      >
        <span>Доставка еды</span> — это самый удобный способ попробовать блюда разных кухонь мира быстро и без хлопот, ведь SakhFood привозит заказы 24 часа 7 дней в неделю. Термосумка сохраняет тепло горячих блюд, поддерживает оптимальную температуру для прохладительных напитков. Вы
        получаете
        именно то, что
        заказывали, всё свежее, горячее и аппетитное. Где заказать готовую еду с бесплатной доставкой? Выбор очевиден! В меню SakhFood:<br>

        <br><span>Суши и роллы. Японская кухня</span> – одна из самых популярных на Сахалине. Соберите себе индивидуальный сет или закажите набор из нашего меню.
        <br><span>Пицца</span> — итальянскую кухню любят многие петербуржцы. Универсальный вариант: это блюдо подойдет и для обеденного перерыва в офис и для домашнего ужина вдвоем или в компании друзей.
        <br><span>Осетинские пироги</span> — горячие, с разными начинками и воздушным тестом с поджаристой корочкой.
        <br><span>Бургеры</span> — сытный стритфуд для любых случаев.
        <br><span>Салаты</span> — лёгкие и питательные для быстрого перекуса.
        <br><span>Закуски</span> — удобный формат для шумных компаний.
        <br><span>Десерты</span> и сладкие пироги — угощение на каждый день.
        <br><span>Соусы</span> — добавляют вкуса и выразительности каждому блюду.
        <br><span>Напитки</span> — натуральные соки, морсы, лимонады и не только.
        <!-- <br><h2>text</h2> -->
        <!-- <a href="google.com">Ссылка</a> -->
        <!-- <ul>
          <li>
            <a href="google.com">Ссылка</a>
          </li>
          <li>
            <p>
              Текст
            </p>
          </li>
        </ul> -->
      </P>
    </slot>
    <div
      v-if="isClamp && clampButtonVisible"
      class="absolute bottom-0 flex items-end justify-center w-full h-66 bg-gradient-to-b from-transparent to-77%"
      :class="color === 'white' ? 'to-white' : 'to-plashka-green'"
    >
      <Button
        variant="outline"
        class="w-full lg:w-fit"
        @click="isClampToglle"
      >
        Показать полностью
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  color: { type: String as PropType<'white' | 'green-light'>, default: 'white' },
  title: { type: String, default: 'Доставка еды 24 часа домой и в офис' }
});
const contentRef = defineModel<HTMLElement | undefined>();
const { isClamp, isClampToglle, clampButtonVisible } = useClamp(contentRef);
</script>
<style scoped lang="scss">
.inside-class:deep(ul) {
  list-style-type: disc!important;
  padding-left: 10px;
  list-style: lower-roman!important;
  li {
    position: relative;
    display: flex;
    &::before {
      content: '';
      display: flex;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-10px);
      background-color: rgb(80 168 10 / var(--un-text-opacity));
    }
  }
}
</style>
